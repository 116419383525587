import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Image from '../../helpers/Image'
import useWindowDimensions from '../../helpers/useWindowDimensions'
import './screen-10.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
// import required modules
import { Autoplay, Pagination } from 'swiper'

const ScreenTen = ({ screen10, siteSettings }) => {
    const { width } = useWindowDimensions()
    const { brandLogo, image, imageSlider } = screen10

    const [carouselAxis, setCarouselAxis] = useState('vertical')

    useEffect(() => {
        width >= 1024
            ? setCarouselAxis('vertical')
            : setCarouselAxis('horizontal')
    }, [width])

    const scrollToTop = () => window.scrollTo(0, 0)

    return (
        <section className="screen-ten overflow-hidden lg:relative lg:z-[-1] lg:flex lg:h-screen lg:!w-full    ">
            <div className="lg:-mr-4 lg:flex">
                {imageSlider[0].image && !imageSlider[1].image && (
                    <div className={`h-full w-full lg:w-[65%]`}>
                        <Image
                            image={imageSlider[0]?.image}
                            className="h-full w-full"
                        />
                    </div>
                )}
                {imageSlider[1].image && (
                    <div className="relative">
                        <Swiper
                            direction={carouselAxis}
                            pagination={{
                                clickable: true,
                                el: '.screen-ten-slider-pagination'
                            }}
                            speed={1500}
                            autoplay={true}
                            loop={true}
                            modules={[Pagination, Autoplay]}
                            className="h-full w-full">
                            {imageSlider.map((item, index) => {
                                const itemId = `item-${index}`
                                return (
                                    <SwiperSlide key={itemId}>
                                        <div className={`h-full w-full`}>
                                            <Image
                                                image={item?.image}
                                                className="h-full w-full"
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                        <div className="screen-ten-slider-pagination absolute !bottom-6 !right-6 !top-auto z-10 flex gap-x-2 lg:flex-col lg:gap-y-2 xl:gap-y-[0.625vw]"></div>
                    </div>
                )}

                <div className="relative aspect-[375/406] w-full lg:aspect-auto lg:w-[65%]">
                    <Image image={image} className="h-full w-full" />

                    <div
                        className="absolute top-8 left-6 cursor-pointer lg:top-[88px] lg:right-6 lg:left-auto xl:top-[11vh] xl:right-[1.875vw]"
                        onClick={() => scrollToTop()}>
                        <Image
                            image={brandLogo}
                            className="w-[245px] xl:w-[23.125vw]"
                        />
                    </div>

                    <div className="absolute top-1/2 left-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-items-center gap-y-5 text-dark_green">
                        <a
                            href={siteSettings?.socialMedia?.instagramUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="regular-xxs flex items-center underline">
                            Follow us
                            <StaticImage
                                src="../../images/Icons/Instagram.svg"
                                className="w-9 xl:w-[2.5vw]"
                                alt="Instagram icon"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenTen
