/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

const Seo = ({ title, description, image, props }) => {
    const { wp, site } = useStaticQuery(
        graphql`
            query {
                wp {
                    generalSettings {
                        title
                        description
                    }
                    siteSettings {
                        siteSettings {
                            featuredImage {
                                sourceUrl
                            }
                            siteLogo {
                                logo2 {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        og {
                            siteName
                            fbTitle
                            fbDescription
                            twitterCreator
                            twitterDescription
                        }
                    }
                }
            }
        `
    )

    const location = useLocation()
    const featuredImage =
        wp?.siteSettings?.siteSettings?.featuredImage?.sourceUrl
    const icon = wp?.siteSettings?.siteSettings?.siteLogo?.logo2?.sourceUrl
    const seo = {
        title: title || wp?.generalSettings?.title,
        description: description || wp?.generalSettings?.description,
        ogImage: featuredImage
    }

    const metas = [
        {
            name: 'icon',
            content: icon
        },
        {
            name: 'description',
            content: seo.description
        },
        {
            name: 'og:image',
            content: seo.ogImage
        },

        {
            name: 'og:type',
            content: 'website'
        },
        {
            name: 'og:title',
            content: props?.opengraphTitle || seo.title
        },
        {
            name: 'og:description',
            content: props?.opengraphDescription || seo.description
        },
        {
            name: 'og:site_name',
            content: site?.siteMetadata?.og?.siteName
        },
        {
            name: 'og:url',
            content: `${site?.siteMetadata?.siteUrl}${location.pathname}`
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            name: 'twitter:description',
            content: props?.twitterDescription || seo.description
        },
        {
            name: 'twitter:title',
            content: props?.twitterTitle || seo.title
        },
        {
            name: 'twitter:image',
            content: seo?.ogImage
        },
        {
            name: 'twitter:creator',
            content: site?.siteMetadata?.og?.twitterCreator
        },
        {
            name: 'googlebot',
            content: 'all'
        },
        {
            name: 'robots',
            content: 'all'
        }
    ]
    if (seo?.keywords) {
        metas.push({
            name: 'keywords',
            content: seo.keywords
        })
    }

    return (
        <>
            <title>{seo.title}</title>
            {metas.map((meta) => (
                <meta key={meta.name} name={meta.name} content={meta.content} />
            ))}
        </>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
}

Seo.propTypes = {
    seo: PropTypes.object
}

export default Seo
