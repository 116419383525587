import { graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import React, { useEffect, useState } from 'react'
import Navigation from '../components/Navigation/Navigation'
import ScreenEight from '../components/ScreenEight/ScreenEight'
import ScreenFive from '../components/ScreenFive/ScreenFive'
import ScreenFour from '../components/ScreenFour/ScreenFour'
import ScreenNine from '../components/ScreenNine/ScreenNine'
import ScreenOne from '../components/ScreenOne/ScreenOne'
import ScreenSeven from '../components/ScreenSeven/ScreenSeven'
import ScreenSix from '../components/ScreenSix/ScreenSix'
import ScreenTen from '../components/ScreenTen/ScreenTen'
import ScreenThree from '../components/ScreenThree/ScreenThree'
import ScreenTwo from '../components/ScreenTwo/ScreenTwo'
import Seo from '../components/seo'
function Home({ data }) {
    gsap.registerPlugin(ScrollTrigger)
    const [activeSection, setActiveSection] = useState(1)

    const {
        screen1,
        screen2,
        screen3,
        screen4,
        screen5,
        screen6,
        screen7,
        screen8,
        screen9,
        screen10
    } = data?.wpPage?.homepageScreens

    const { siteSettings } = data?.settings?.siteSettings

    useEffect(() => {
        const sections = gsap.utils.toArray('section')
        const wrapper = document.querySelector('.wrapper')

        const tl = gsap.timeline()

        ScrollTrigger.saveStyles('.wrapper')

        let mm = gsap.matchMedia()

        mm.add('(min-width: 1024px)', () => {
            // tl.set('.wrapper', { opacity: 0 }).to('.wrapper', {
            //     opacity: 1
            // })
            let maxWidth = 0
            const getMaxWidth = () => {
                maxWidth = 0
                sections.forEach((section) => {
                    maxWidth += section.offsetWidth
                })
            }

            ScrollTrigger.addEventListener('refreshInit', getMaxWidth)
            getMaxWidth()

            gsap.to(sections, {
                xPercent: () => -100 * (sections.length - 1),
                ease: 'none',
                scrollTrigger: {
                    invalidateOnRefresh: true,
                    trigger: '.wrapper',
                    pin: true,
                    scrub: 0.6,
                    start: 'top top',
                    end: () => `+=${maxWidth}`
                }
            })

            sections.forEach((sct, i) => {
                ScrollTrigger.create({
                    trigger: sct,
                    start: () =>
                        'top top-=' +
                        (sct.offsetLeft - window.innerWidth / 2) *
                            ((wrapper.clientWidth * (sections.length - 1)) /
                                (wrapper.clientWidth * (sections.length - 1) -
                                    window.innerWidth)),
                    end: () =>
                        '+=' +
                        sct.offsetWidth *
                            ((wrapper.clientWidth * (sections.length - 1)) /
                                (wrapper.clientWidth * (sections.length - 1) -
                                    window.innerWidth)),
                    toggleClass: { targets: sct, className: 'active' },
                    onToggle: () => setActiveSection(i + 1),
                    invalidateOnRefresh: true
                })
            })
        })

        return () => mm.revert()
    }, [])

    return (
        <div>
            <div className="wrapper -ml-[2px] flex flex-col lg:flex-row lg:flex-nowrap">
                <ScreenOne screen1={screen1} />
                <ScreenTwo screen2={screen2} />
                <ScreenThree screen3={screen3} />
                <ScreenFour screen4={screen4} />
                <ScreenFive screen5={screen5} />
                <ScreenSix screen6={screen6} />
                <ScreenSeven screen7={screen7} overflowImage={screen8?.image} />
                <ScreenEight />
                <ScreenNine screen9={screen9} />
                <ScreenTen screen10={screen10} siteSettings={siteSettings} />
                <Navigation
                    activeSection={activeSection}
                    siteSettings={siteSettings}
                />
            </div>
        </div>
    )
}

export default Home

export const Head = ({ data }) => {
    const { seo } = data?.yoastSeo
    return <Seo title={seo?.title} description={seo?.metaDesc} props={seo} />
}

export const query = graphql`
    query MyQuery {
        wpPage(slug: { eq: "home" }) {
            homepageScreens {
                screen1 {
                    address
                    title
                    imageSlider {
                        image {
                            ...IMAGE
                        }
                    }
                    logo {
                        ...IMAGE
                    }
                }
                screen2 {
                    aboutLocation
                    aboutLocationDescription
                    title
                    verticalTitle
                }
                screen3 {
                    amenitiesDescription
                    amenitiesTitle
                    title
                    mobileTitle
                    image {
                        ...IMAGE
                    }
                }
                screen4 {
                    imageOne {
                        ...IMAGE
                    }
                    imageTwo {
                        ...IMAGE
                    }
                }
                screen6 {
                    images {
                        image {
                            ...IMAGE
                        }
                    }
                    title1
                    title2
                }
                screen7 {
                    title
                    description
                    subtitle
                    image {
                        ...IMAGE
                    }
                }
                screen8 {
                    image {
                        ...IMAGE
                    }
                }
                screen10 {
                    brandLogo {
                        ...IMAGE
                    }
                    imageSlider {
                        image {
                            ...IMAGE
                        }
                    }
                    image {
                        ...IMAGE
                    }
                }
                screen5 {
                    quote
                }
                screen9 {
                    accommodationSubtitle
                    accomodationDescription
                    eventDescription
                    eventHireSubtitle
                    makeABooking
                    title
                }
            }
        }
        yoastSeo: wpPage(slug: { eq: "home" }) {
            seo {
                title
                metaDesc
                opengraphTitle
                opengraphDescription
                twitterTitle
                twitterDescription
            }
        }
        settings: wp {
            siteSettings {
                siteSettings {
                    makeABookingLink {
                        target
                        title
                        url
                    }
                    siteLogo {
                        logo1 {
                            ...IMAGE
                        }
                        logo1Mobile: logo1 {
                            ...IMAGE
                        }
                        logo2 {
                            ...IMAGE
                        }
                    }
                    socialMedia {
                        instagramUrl
                    }
                }
            }
        }
    }
`
