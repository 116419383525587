import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import parse from 'html-react-parser'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Image from '../../helpers/Image'
import useWindowDimensions from '../../helpers/useWindowDimensions'
import arrowRight from '../../images/Icons/Arrow_Right.svg'
import './screen-1.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
// import required modules
import { Autoplay, Pagination } from 'swiper'

function ScreenOne({ screen1 }) {
    const { width } = useWindowDimensions()
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const [carouselAxis, setCarouselAxis] = useState('vertical')
    const { imageSlider, address, title, logo } = screen1

    useEffect(() => {
        width >= 1024
            ? setCarouselAxis('vertical')
            : setCarouselAxis('horizontal')
    }, [width])

    useEffect(() => {
        const element = ref.current
        const ctx = gsap.context(() => {
            gsap.fromTo(
                '.screen-1__text',
                {
                    opacity: 0,
                    yPercent: 50
                },
                {
                    opacity: 1,
                    duration: 1.5,
                    delay: 2,
                    yPercent: 0,
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: -1
                    },
                    onStart: () => {
                        document.querySelector('body').style.overflowY =
                            'scroll'
                    }
                }
            )

            gsap.fromTo(
                '.screen-1__title',
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    duration: 1.5,
                    delay: 2,
                    yPercent: 0,
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: -1
                    }
                }
            )
        }, ref)

        return () => ctx.revert()
    }, [])

    const scrollNextSlide = useCallback(() => {
        window.scrollTo(0, width * (1 + 1 / 9.5))
    }, [width])
    return (
        <section
            className="screen-one translate-y-[1px] overflow-hidden lg:h-screen lg:translate-y-0"
            ref={ref}>
            <div className="flex h-full flex-col justify-end lg:flex-row">
                <div className="relative aspect-[375/406] w-full lg:aspect-auto lg:h-full lg:w-[57.5%]">
                    {imageSlider[0].image && !imageSlider[1].image && (
                        <div className={`h-full w-full`}>
                            <Image
                                image={imageSlider[0]?.image}
                                className="h-full w-full"
                                loading="eager"
                            />
                        </div>
                    )}
                    {imageSlider[1].image && (
                        <Swiper
                            direction={carouselAxis}
                            pagination={{
                                clickable: true,
                                el: '.screen-one-slider-pagination'
                            }}
                            speed={1500}
                            autoplay={true}
                            loop={true}
                            modules={[Pagination, Autoplay]}
                            className="h-full w-full">
                            {imageSlider.map((item, index) => {
                                const itemId = `item-${index}`
                                return (
                                    <SwiperSlide key={itemId}>
                                        <div className={`h-full w-full`}>
                                            <Image
                                                image={item?.image}
                                                className="h-full w-full"
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    )}
                    <div className="screen-one-slider-pagination absolute !bottom-6 !right-6 !top-auto z-10 flex gap-x-2 lg:flex-col lg:gap-y-2 xl:gap-y-[0.625vw]"></div>
                </div>
                <div className="screen-1__content flex h-fit min-h-[400px] w-full flex-col items-start justify-between bg-dark_green pt-10 pl-5 pr-10 pb-5 lg:h-full lg:w-[42.5%] lg:pt-20 lg:pl-10 xl:pl-[9.375vw] xl:pb-[1.5vh] xl:pt-[6.25vh]">
                    <div className="mb-16 h-auto w-4/5 sm:w-1/2 lg:mb-0 lg:w-[296px] xl:w-[23vw]">
                        <Image className="h-full w-full" image={logo} />
                    </div>

                    <div className="w-4/5 lg:w-full">
                        <div className="screen-1__text opacity-0">
                            {address && (
                                <p className=" regular-xs mb-4 text-beige">
                                    {parse(address)}
                                </p>
                            )}
                        </div>
                        <div className="screen-1__title opacity-0">
                            {title && (
                                <h1 className=" book-lg text-beige lg:max-w-[400px] xl:max-w-none">
                                    {title}
                                </h1>
                            )}
                        </div>
                    </div>

                    <div
                        className="regular-xxs hidden cursor-pointer items-center text-beige lg:inline-flex"
                        onClick={scrollNextSlide}>
                        <img
                            src={arrowRight}
                            alt="scroll right"
                            className="mr-2 h-10 w-10 xl:mr-[1vw]"
                        />
                        Scroll to explore
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenOne
