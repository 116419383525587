import { gsap } from 'gsap'
import React, { useEffect, useRef } from 'react'

const ScreenFive = ({ screen5 }) => {
    const { quote } = screen5
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        const mm = gsap.matchMedia(element)
        mm.add('(min-width: 1024px)', () => {
            gsap.fromTo(
                element.querySelector('.content'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 4
                    }
                }
            )
        })

        return () => mm.revert()
    }, [])
    return (
        <section className="lg:flex lg:h-screen" ref={ref}>
            <div className="bg-dark_green px-5 py-16 lg:ml-auto lg:flex lg:w-[41.25%] lg:items-end lg:px-20 lg:pb-[88px] xl:pl-[8.4vw] xl:pr-[10vw] xl:pb-[11vh]">
                {quote && (
                    <p className="book-sm content text-beige lg:opacity-0">
                        {quote}
                    </p>
                )}
            </div>
        </section>
    )
}

export default ScreenFive
