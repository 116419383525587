import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import useWindowDimensions from '../../helpers/useWindowDimensions'
const ScreenFour = ({ screen4 }) => {
    const { height, width } = useWindowDimensions()
    const { imageOne, imageTwo } = screen4

    const [aspectRatio, setAspectRatio] = useState(2)
    const ref = useRef(null)
    const [isImageLoading, setIsImageLoading] = useState(false)

    useEffect(() => {
        // make the image on screen 4 overflow 58.75% of screen 4
        const currentRatio = ((0.4125 + 0.5875) * width) / height

        setAspectRatio(currentRatio)
    }, [width, height])

    useEffect(() => {
        if (isImageLoading) {
            const element = ref.current
            const mm = gsap.matchMedia(ref)
            mm.add('(max-width: 1023px)', () => {
                const tl = gsap.timeline()
                tl.to('.image-1-wrapper', {
                    y: -44,
                    scrollTrigger: {
                        trigger: '.image-1-wrapper',
                        scrub: 0.6,
                        invalidateOnRefresh: true,
                        start: 'top-=300',
                        end: 'bottom+=100'
                        // markers: true
                    }
                })

                tl.set('.image-2', {
                    scale: 1.1,
                    paused: true,
                    immediateRender: true
                }).to('.image-2', {
                    scale: 1,
                    scrollTrigger: {
                        trigger: '.image-2',
                        scrub: 0.6,
                        invalidateOnRefresh: true,
                        start: 'top-=300',
                        end: 'bottom+=100'
                        // markers: true
                    }
                })
            })

            mm.add('(min-width: 1024px)', () => {
                gsap.fromTo(
                    element
                        .querySelector('.image-1')
                        .querySelectorAll('img')[2],
                    {
                        scale: 1.3,
                        xPercent: -15,
                        yPercent: -15
                    },
                    {
                        xPercent: 15,
                        boxShadow: '0px 0px 64px #0000001A',
                        ease: 'power4.out',

                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 2,
                            end: element.clientWidth * 5
                        }
                    }
                )
                gsap.fromTo(
                    element.querySelector('.image-1'),
                    {},
                    {
                        boxShadow: '0px 0px 64px #0000001A',
                        ease: 'power4.out',

                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 3,
                            end: element.clientWidth * 5
                        }
                    }
                )

                gsap.fromTo(
                    element.querySelector('.image-1-wrapper'),
                    {},
                    {
                        xPercent: 16.5,

                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 2,
                            end: element.clientWidth * 4
                        }
                    }
                )

                gsap.fromTo(
                    element
                        .querySelector('.image-2')
                        .querySelectorAll('img')[2],
                    {
                        scale: 1.2,
                        xPercent: -10,
                        yPercent: -5
                    },
                    {
                        xPercent: 10,
                        ease: 'power4.out',
                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 2,
                            end: element.clientWidth * 6
                        }
                    }
                )
            })

            return () => mm.revert()
        }
    }, [isImageLoading])

    return (
        <section className="relative isolate lg:flex lg:h-screen   " ref={ref}>
            <div className="absolute left-full -z-[1] hidden h-full w-full bg-dark_green lg:block"></div>
            <div className="aspect-[375/332] px-5 lg:w-[58.75%]  lg:pt-[11vh]">
                <div className="image-1-wrapper relative mx-auto h-full w-full -translate-y-16 shadow-[0_0_64px] shadow-black/10 will-change-transform lg:aspect-square lg:h-auto lg:w-[67%] lg:translate-y-0">
                    <GatsbyImage
                        image={getImage(imageOne?.localFile)}
                        className="image-1 h-full w-full"
                        alt={imageOne?.altText || imageOne?.title}
                        onLoad={() => {
                            setIsImageLoading(true)
                        }}
                    />
                </div>
            </div>

            <div className="aspect-[375/406] w-full overflow-hidden lg:w-[41.25%] lg:overflow-visible">
                <GatsbyImage
                    image={getImage(imageTwo?.localFile)}
                    className="image-2 h-full w-full lg:w-auto"
                    objectPosition="bottom"
                    alt={imageTwo?.altText || imageTwo?.title}
                    onLoad={() => {
                        setIsImageLoading(true)
                    }}
                    style={{ aspectRatio: `${aspectRatio}` }}
                />
            </div>
        </section>
    )
}

export default ScreenFour
