import { gsap } from 'gsap'
import React, { useEffect, useRef } from 'react'
import Image from '../../helpers/Image'
import './screen-6.scss'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const ScreenSix = ({ screen6 }) => {
    const { images, title1, title2 } = screen6
    const ref = useRef(null)

    useEffect(() => {
        const element = ref.current

        let mm = gsap.matchMedia(ref)

        const scrollTriggerConfig = {
            trigger: element,
            scrub: 0.6,
            invalidateOnRefresh: true,
            // markers: true,
            start: 'top-=300',
            end: 'bottom-=100'
        }

        mm.add('(max-width: 1023px)', () => {
            const tl = gsap.timeline({
                defaults: {
                    ease: 'power1'
                }
            })

            // slider
            tl.set('.slider', {
                xPercent: -13,
                immediateRender: true,
                paused: true
            }).to('.slider', {
                xPercent: -87,
                scrollTrigger: scrollTriggerConfig
            })
            tl.set('.title-1', {
                xPercent: 33,
                yPercent: -40,
                immediateRender: true,
                paused: true
            }).to('.title-1', {
                xPercent: -33,
                scrollTrigger: scrollTriggerConfig
            })

            tl.set('.title-2', {
                xPercent: -50,
                yPercent: -40,
                immediateRender: true,
                paused: true
            }).to('.title-2', {
                xPercent: 15,
                scrollTrigger: scrollTriggerConfig
            })
        })
        mm.add('(min-width: 1024px)', () => {
            gsap.fromTo(
                element.querySelector('.title-1'),
                {
                    xPercent: -65,
                    yPercent: -40
                },
                {
                    xPercent: -35,
                    ease: 'power1',

                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4,
                        end: element.clientWidth * 7
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.title-2'),
                {
                    xPercent: -30
                },
                {
                    xPercent: -50,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4,
                        end: element.clientWidth * 7
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.slider'),
                {
                    yPercent: -25,
                    xPercent: -50
                },
                {
                    yPercent: -85,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4.8,
                        end: element.clientWidth * 6
                    }
                }
            )
        })
        mm.add('(min-width: 2300px)', () => {
            gsap.fromTo(
                element.querySelector('.title-1'),
                {
                    xPercent: -65,
                    yPercent: -20
                },
                {
                    xPercent: -35,
                    ease: 'power1',

                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4,
                        end: element.clientWidth * 7
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.title-2'),
                {
                    xPercent: -30,
                    yPercent: 40
                },
                {
                    xPercent: -50,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4,
                        end: element.clientWidth * 7
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.slider'),
                {
                    yPercent: -25,
                    xPercent: -50
                },
                {
                    yPercent: -85,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 4.8,
                        end: element.clientWidth * 6
                    }
                }
            )
        })

        return () => mm.revert()
    }, [])

    return (
        <section
            className="screen-6 overflow-hidden lg:flex lg:h-screen lg:overflow-visible"
            ref={ref}>
            <div className="w-full bg-gold pt-[127px] pb-[174px] lg:flex lg:items-end lg:pb-[88px] xl:pb-[11vh]">
                <div className="relative mx-auto aspect-square w-[65%] lg:w-[400px] xl:w-[31.25vw]">
                    <div className="slider absolute top-0 left-1/2 flex h-full gap-4 will-change-transform lg:top-1/2 lg:h-auto lg:w-full lg:flex-col xl:gap-y-[1.25vw]">
                        {images?.map((item, index) => {
                            return (
                                <Image
                                    key={`item-${index}`}
                                    image={item?.image}
                                    className="aspect-square h-full lg:h-auto lg:w-full"
                                />
                            )
                        })}
                    </div>

                    {title1 && (
                        <h3 className="title-1 font-heading-italic lg:italic-2xl absolute w-max pr-[10%] text-7xl leading-[94px] text-beige will-change-transform lg:left-1/2">
                            {title1}
                        </h3>
                    )}

                    {title2 && (
                        <h3 className="title-2 font-heading-italic lg:italic-2xl absolute left-[5%] top-full w-max pr-[10%] text-7xl leading-[94px] text-beige will-change-transform lg:top-0 lg:left-1/2 lg:translate-y-[125px] xl:translate-y-[54.4%]">
                            {title2}
                        </h3>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ScreenSix
