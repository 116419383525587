import { StaticImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import React, { useEffect, useRef, useState } from 'react'
import horizontalLoop from '../../helpers/horizontalLoop'
import parse from 'html-react-parser'

function ScreenTwo({ screen2 }) {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const [isImageLoading, setIsImageLoading] = useState(false)
    const { title, aboutLocation, aboutLocationDescription, verticalTitle } =
        screen2

    useEffect(() => {
        const element = ref.current

        // Add class for title lines
        const titleNode = element.querySelector('.title').querySelectorAll('p')
        titleNode.forEach((node, index) => {
            node.classList.add(`title-${index + 1}`)
        })

        // gsap setting

        const mm = gsap.matchMedia(ref)

        mm.add('(max-width: 1023px)', () => {
            const tl = gsap.timeline()

            tl.fromTo(
                element.querySelector('.text-single'),
                {
                    xPercent: 0
                },
                {
                    xPercent: -60,
                    ease: 'power1',
                    scrollTrigger: {
                        trigger: element,
                        scrub: 0.6,
                        invalidateOnRefresh: true,
                        start: 'top-=200',
                        end: 'top+=300'
                        // markers: true
                    }
                }
            )

            tl.set('.image', {
                paused: true,
                immediateRender: true,
                yPercent: -5
            }).to(
                '.image',
                {
                    yPercent: 5,
                    ease: 'power1',
                    scrollTrigger: {
                        trigger: '.image',
                        scrub: 0.6,
                        invalidateOnRefresh: true,
                        start: 'top-=300',
                        end: 'bottom+=100'
                        // markers: true
                    }
                },
                0
            )
        })

        mm.add('(min-width: 1024px)', () => {
            const loops = gsap.utils.toArray('.text-single').map((line, i) => {
                const links = line.querySelectorAll('.screen-two__big-text')
                return horizontalLoop(gsap, links, {
                    repeat: -1,
                    speed: 1,
                    reversed: false,
                    paddingRight: parseFloat(
                        gsap.getProperty(links[0], 'marginRight', 'px')
                    )
                })
            })

            ScrollTrigger.create({
                invalidateOnRefresh: true,
                onUpdate: (self) => {
                    const n = 0.003
                    const speed = self.getVelocity() * n
                    const maxSpeed = 4
                    const minSpeed = -4
                    loops.forEach((tl) => {
                        gsap.to(tl, {
                            timeScale:
                                speed < minSpeed
                                    ? minSpeed
                                    : speed > maxSpeed
                                    ? maxSpeed
                                    : speed,
                            overwrite: true,
                            scrollTrigger: {
                                trigger: element,
                                start: -1
                            }
                        })
                    })
                }
            })

            gsap.fromTo(
                element.querySelector('.title-1'),
                {
                    xPercent: 35
                },
                {
                    xPercent: 8,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 0,
                        end: element.clientWidth * 3
                    }
                }
            )
            gsap.fromTo(
                element.querySelector('.title-2'),
                {
                    xPercent: 15
                },
                {
                    xPercent: 0,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 0,
                        end: element.clientWidth * 3
                    }
                }
            )
            gsap.fromTo(
                element.querySelector('.title-3'),
                {
                    xPercent: 0
                },
                {
                    xPercent: 16,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 0,
                        end: element.clientWidth * 3
                    }
                }
            )

            if (isImageLoading) {
                gsap.fromTo(
                    element.querySelector('.image').querySelectorAll('img')[2],
                    {
                        scale: 1.4,
                        xPercent: -20,
                        yPercent: -20
                    },
                    {
                        xPercent: 20,
                        ease: 'power4.out',
                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: 0,
                            end: element.clientWidth * 5
                        }
                    }
                )
            }

            gsap.fromTo(
                element.querySelector('.pre-header'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth / 2
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.content'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth / 2
                    }
                }
            )
        })

        return () => mm.revert()
    }, [isImageLoading])

    return (
        <section
            className="screen-two screen relative h-fit overflow-hidden bg-cover bg-right bg-no-repeat lg:-ml-1 lg:h-screen   "
            ref={ref}>
            <div className="flex h-full flex-col lg:flex-row">
                <div className="flex flex-col items-start justify-start bg-dark_green px-5 pt-10 pb-5 lg:relative lg:h-full lg:w-3/5 lg:flex-row lg:flex-nowrap lg:items-center lg:gap-x-[102px] lg:pt-0 lg:pb-0">
                    {verticalTitle && (
                        <div className="italic-2xl relative min-h-[126px] text-beige lg:min-w-[210px] lg:self-end xl:min-w-[15vw]">
                            <div className="text-single absolute whitespace-nowrap will-change-transform lg:origin-top-left lg:translate-y-full lg:-rotate-90">
                                <div className="screen-two__big-text mr-8 inline-block pr-[2%]">
                                    {verticalTitle}
                                </div>
                                <div className="screen-two__big-text mr-8 hidden h-max w-max pr-[2%] lg:inline-block">
                                    {verticalTitle}
                                </div>
                                <div className="screen-two__big-text mr-8 hidden pr-[2%] lg:inline-block">
                                    {verticalTitle}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="pt-32 pb-20 lg:flex lg:h-full lg:flex-col lg:justify-between lg:pt-[88px] lg:pb-52 xl:pt-[11vh] xl:pb-[26vh]">
                        <div>
                            {aboutLocation && (
                                <p className="regular-xxs pre-header mb-4 text-beige/50 lg:opacity-0">
                                    {aboutLocation}
                                </p>
                            )}
                            {aboutLocationDescription && (
                                <p className="content regular-xs mb-16  text-beige lg:max-w-[250px] lg:opacity-0 xl:max-w-[71%]">
                                    {aboutLocationDescription}
                                </p>
                            )}
                        </div>
                        {title && (
                            <h1 className="book-md title text-beige lg:block lg:whitespace-nowrap">
                                {parse(title)}
                            </h1>
                        )}
                    </div>
                </div>
                <div className="image relative z-[-1] min-h-[640px] w-full scale-110 bg-cover bg-left bg-no-repeat lg:min-h-full lg:w-2/5 lg:scale-100   ">
                    <div className="absolute inset-0">
                        <div className="hidden h-full lg:block">
                            <StaticImage
                                src="../../images/Screen/02_Hillside_Carool_resize.jpg"
                                alt="Hillside_Carool 2"
                                className="h-full w-full "
                            />
                        </div>
                        <div className="h-full w-full lg:hidden">
                            <StaticImage
                                src="../../images/Screen/02_Hillside_Carool_mobile.jpg"
                                alt="Hillside_Carool 2"
                                className="h-full w-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenTwo
