import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import Image from '../../helpers/Image'
import useWindowDimensions from '../../helpers/useWindowDimensions'

const ScreenSeven = ({ screen7, overflowImage }) => {
    const { height, width } = useWindowDimensions()
    const { description, image, subtitle, title } = screen7
    const ref = useRef(null)
    const [aspectRatio, setAspectRatio] = useState(4 / 3)
    const [isImageLoading, setIsImageLoading] = useState(false)

    useEffect(() => {
        // make the image on screen 3 overflow 100% of screen 4
        const currentRatio = ((0.287 + 1) * width) / height

        setAspectRatio(currentRatio)
    }, [width, height])

    useEffect(() => {
        const element = ref.current

        let mm = gsap.matchMedia(ref)

        mm.add('(max-width: 1023px)', () => {
            const tl = gsap.timeline()

            tl.set('.title', {
                xPercent: -30,
                paused: true,
                immediateRender: true
            }).to('.title', {
                xPercent: 10,
                ease: 'power1',
                scrollTrigger: {
                    trigger: '.title',
                    scrub: 0.6,
                    invalidateOnRefresh: true,
                    start: 'top-=400',
                    end: 'top+=200'
                }
            })

            tl.set('.img-class', {
                scale: 1.1,
                yPercent: -5,
                paused: true,
                immediateRender: true
            }).to('.img-class', {
                yPercent: 5,
                ease: 'power1',
                scrollTrigger: {
                    trigger: '.img-class',
                    scrub: 0.6,
                    invalidateOnRefresh: true,
                    start: 'top-=200',
                    end: 'bottom+=100'
                    // markers: true
                }
            })
        })

        mm.add('(min-width: 1024px)', () => {
            gsap.fromTo(
                element.querySelector('.title'),
                {},
                {
                    xPercent: 20,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 6,
                        end: element.clientWidth * 8.5
                    }
                }
            )

            if (isImageLoading) {
                gsap.fromTo(
                    element.querySelector('.image').querySelectorAll('img')[2],
                    {
                        scale: 1.2,
                        xPercent: 10,
                        yPercent: -10
                    },
                    {
                        xPercent: -10,
                        ease: 'power4.out',
                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 5.7,
                            end: element.clientWidth * 8
                        }
                    }
                )
            }

            gsap.fromTo(
                element.querySelector('.pre-header'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 6
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.content'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 6
                    }
                }
            )
        })

        return () => mm.revert()
    }, [isImageLoading])
    return (
        <section
            className="overflow-hidden text-beige lg:z-[-1] lg:flex lg:h-screen lg:overflow-visible"
            ref={ref}>
            <div className="h-[640px] w-full lg:h-full lg:w-[41.25%]">
                <Image image={image} className="h-full w-full" />
            </div>

            <div className="relative lg:flex lg:w-[58.75%]">
                <div className="bg-gold px-[21px] pt-8 pb-20 lg:w-[71.3%] lg:px-20 lg:pt-[88px] xl:px-[9.375vw] xl:pt-[11vh]">
                    {subtitle && (
                        <p className="pre-header regular-xxs mb-4 text-beige/50 lg:opacity-0">
                            {subtitle}
                        </p>
                    )}
                    {description && (
                        <p className="content regular-xs lg:opacity-0">
                            {description}
                        </p>
                    )}
                </div>
                <div className="aspect-[375/406] w-full lg:w-[28.7%]">
                    <GatsbyImage
                        image={getImage(overflowImage?.localFile)}
                        className="image h-full w-full lg:w-auto"
                        imgClassName="img-class"
                        style={{ aspectRatio: `${aspectRatio}` }}
                        onLoad={() => {
                            setIsImageLoading(true)
                        }}
                        alt={overflowImage?.altText || overflowImage?.title}
                        objectPosition={width >= 1024 ? 'left' : 'right'}
                    />
                </div>

                {title && (
                    <h2 className="title italic-2xl absolute bottom-0 translate-y-[60px] -translate-x-2 pr-[10%] pt-[2%] tracking-[-13px] will-change-transform lg:translate-x-[65px] lg:translate-y-[70px] lg:text-[335px] lg:leading-none xl:text-[26vw]">
                        {title}
                    </h2>
                )}
            </div>
        </section>
    )
}

export default ScreenSeven
