import { Link } from 'gatsby'
import React, { useCallback } from 'react'
import Image from '../../helpers/Image'

const Navigation = ({ activeSection, siteSettings }) => {
    // Slides that need logo to be green
    const green = [3, 4, 9]

    const scrollToTop = useCallback(() => window.scrollTo(0, 0), [])
    const scrollToBooking = useCallback(() => {
        const makeBooking = document.querySelector('#make-booking')

        // 9.05 is based on the end: () => maxWidth in index.js
        // The scroll range will double -> add a width equal to offsetWidth(window width) / 9

        window.scrollTo(0, makeBooking.offsetWidth * 8 * (1 + 1 / 9.05))
    }, [])
    return (
        <>
            <div
                className="absolute top-6 left-5 cursor-pointer transition-all duration-300 lg:fixed lg:left-10 lg:top-10 xl:top-[3vh] xl:left-[1.56vw]"
                onClick={() => scrollToTop()}>
                <Link to="/" className="relative block w-14 xl:w-[4.375vw]">
                    <div className="w-full lg:hidden">
                        <Image
                            className={`block ${
                                green.includes(activeSection)
                                    ? 'opacity-0'
                                    : 'opacity-100'
                            }`}
                            image={siteSettings?.siteLogo?.logo1Mobile}
                        />
                    </div>
                    <div className="hidden lg:block">
                        <Image
                            className={`w-full ${
                                green.includes(activeSection)
                                    ? 'opacity-0'
                                    : 'opacity-100'
                            }`}
                            image={siteSettings?.siteLogo?.logo1}
                        />
                    </div>

                    <div className="absolute top-0 left-0 hidden w-full lg:block">
                        <Image
                            className={`w-full ${
                                green.includes(activeSection)
                                    ? 'opacity-100'
                                    : 'opacity-0'
                            }`}
                            image={siteSettings?.siteLogo?.logo2}
                        />
                    </div>
                </Link>
            </div>

            <div
                className={`regular-xs left-6 top-[calc(100%-24px)] hidden -translate-y-full underline transition-all duration-300 lg:fixed lg:block xl:left-[2.5vw] xl:top-[calc(100%-3vh)] ${
                    green.includes(activeSection)
                        ? 'text-dark_green'
                        : 'text-beige'
                }`}>
                {siteSettings?.makeABookingLink?.title && (
                    <a
                        onClick={() => scrollToBooking()}
                        className="cursor-pointer">
                        {siteSettings?.makeABookingLink?.title}
                    </a>
                )}
            </div>
        </>
    )
}

export default Navigation
