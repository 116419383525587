import { gsap } from 'gsap'
import React, { useEffect, useRef } from 'react'
import horizontalLoop from '../../helpers/horizontalLoop'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import parse from 'html-react-parser'
import './screen-9.scss'

const ScreenNine = ({ screen9 }) => {
    const {
        accommodationSubtitle,
        accomodationDescription,
        eventDescription,
        eventHireSubtitle,
        makeABooking,
        title
    } = screen9
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)

    useEffect(() => {
        const element = ref.current
        const mm = gsap.matchMedia(ref)

        mm.add('(max-width: 1023px)', () => {
            gsap.to('.screen-9-text-single', {
                xPercent: -50,
                ease: 'power1',
                scrollTrigger: {
                    trigger: element,
                    scrub: 0.6,
                    invalidateOnRefresh: true,
                    start: 'top-=500',
                    end: 'top+=100'
                    // markers: true
                }
            })
        })

        mm.add('(min-width: 1024px)', () => {
            const loops = gsap.utils
                .toArray('.screen-9-text-single')
                .map((line, i) => {
                    const links = line.querySelectorAll('.big-text')
                    return horizontalLoop(gsap, links, {
                        repeat: -1,
                        speed: 1,
                        reversed: false,
                        paddingRight: parseFloat(
                            gsap.getProperty(links[0], 'marginRight', 'px')
                        )
                    })
                })

            ScrollTrigger.create({
                invalidateOnRefresh: true,
                trigger: '.wrapper',
                start: element.clientWidth * 8,
                end: element.clientWidth * 10,
                // markers: true,
                onUpdate: (self) => {
                    const n = 0.03
                    const speed = self.getVelocity() * n
                    const maxSpeed = 4
                    const minSpeed = -4
                    loops.forEach((tl) => {
                        gsap.to(tl, {
                            timeScale:
                                speed < minSpeed
                                    ? minSpeed
                                    : speed > maxSpeed
                                    ? maxSpeed
                                    : speed,
                            overwrite: true
                        })
                    })
                }
            })

            gsap.fromTo(
                element.querySelector('.header'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 8
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.content-1'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 8
                    }
                }
            )
            gsap.fromTo(
                element.querySelector('.content-2'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.75,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 8
                    }
                }
            )
        })

        return () => mm.revert()
    }, [])

    return (
        <section
            className="screen-9 overflow-hidden bg-beige text-dark_green lg:z-[-1] lg:flex lg:h-screen   "
            ref={ref}
            id="make-booking">
            <div className="w-full px-5 pb-16 lg:flex lg:flex-col lg:justify-between lg:px-6 lg:pb-[140px] xl:pb-[17.5vh]">
                {title && (
                    <h2 className="font-heading-italic mb-16 w-max text-7xl leading-[1.3] lg:relative lg:text-[120px] xl:text-[9.375vw]">
                        <div className=" screen-9-text-single will-change-transform lg:origin-top-left lg:translate-y-[100vh] lg:-rotate-90">
                            <div className="big-text mr-8 inline-block">
                                {title}
                            </div>
                            <div className="big-text mr-8 hidden lg:inline-block">
                                {title}
                            </div>
                            <div className="big-text mr-8 hidden lg:inline-block">
                                {title}
                            </div>
                        </div>
                    </h2>
                )}

                <div className="grid gap-y-6 lg:ml-auto lg:w-[92%] lg:px-[16%]">
                    {makeABooking && (
                        <h3 className="book-md header leading-[48px] lg:leading-[64px] lg:opacity-0">
                            {makeABooking}
                        </h3>
                    )}
                    <div className="flex flex-col gap-6 lg:flex-row lg:justify-between">
                        <div className="content-1 lg:max-w-[296px] lg:opacity-0 xl:max-w-[41.57%]">
                            {accommodationSubtitle && (
                                <span className="regular-xxs text-dark_green/50">
                                    {accommodationSubtitle}
                                </span>
                            )}
                            {accomodationDescription && (
                                <p className="book-sm pt-4">
                                    {parse(accomodationDescription)}
                                </p>
                            )}
                        </div>
                        <div className="content-2 lg:max-w-[296px] lg:opacity-0 xl:max-w-[41.57%]">
                            {eventHireSubtitle && (
                                <span className="regular-xxs text-dark_green/50">
                                    {eventHireSubtitle}
                                </span>
                            )}

                            {eventDescription && (
                                <p className="book-sm pt-4">
                                    {parse(eventDescription)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ScreenNine
