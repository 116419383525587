import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import React, { useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import useWindowDimensions from '../../helpers/useWindowDimensions'
const ScreenThree = ({ screen3 }) => {
    const { height, width } = useWindowDimensions()
    const { amenitiesDescription, amenitiesTitle, title, mobileTitle, image } =
        screen3

    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const [aspectRatio, setAspectRatio] = useState(4 / 3)
    const [isImageLoading, setIsImageLoading] = useState(false)

    //add class for title
    useEffect(() => {
        const titleNode = ref.current
            .querySelector('.title')
            .querySelectorAll('p')
        titleNode.forEach((node, index) => {
            node.classList.add(
                `title-${index + 1}`,
                'lg:whitespace-nowrap',
                'lg:justify-end',
                'leading-[48px]',
                'lg:leading-[64px]'
            )
        })
    }, [])

    useEffect(() => {
        // make the image on screen 3 overflow 38% of screen 4
        // 41,25 is the width from screen 3
        // 58,75 is the width we want to overflow from screen 4
        // 0.38 is the portion need to overflow
        // 24 is margin top and bottom of the image

        const marginY = width >= 1280 ? 0.03 * height : 24

        const currentRatio =
            ((0.5875 * 0.38 + 0.4125) * width) / (height - marginY * 2)

        setAspectRatio(currentRatio)
    }, [width, height])

    useEffect(() => {
        const element = ref.current
        const mm = gsap.matchMedia(ref)

        mm.add('(min-width: 1024px)', () => {
            gsap.fromTo(
                element.querySelector('.title-1'),
                {
                    xPercent: 33
                },
                {
                    xPercent: -9.6,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 1,
                        end: element.clientWidth * 3.5
                    }
                }
            )
            gsap.fromTo(
                element.querySelector('.title-2'),
                {
                    xPercent: 0
                },
                {
                    xPercent: -2,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 1,
                        end: element.clientWidth * 3.5
                    }
                }
            )
            gsap.fromTo(
                element.querySelector('.title-3'),
                {
                    xPercent: 0
                },
                {
                    xPercent: 15.3,
                    ease: 'power4.out',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        scrub: 0.6,
                        start: element.clientWidth * 1,
                        end: element.clientWidth * 3.5
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.pre-header'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 1.5
                    }
                }
            )

            gsap.fromTo(
                element.querySelector('.content'),
                {
                    opacity: 0,
                    yPercent: 25
                },
                {
                    opacity: 1,
                    yPercent: 0,
                    duration: 1.5,
                    delay: 0.5,
                    ease: 'power1',
                    scrollTrigger: {
                        invalidateOnRefresh: true,
                        trigger: element,
                        start: element.clientWidth * 1.5
                    }
                }
            )
        })

        return () => mm.revert()
    }, [])

    useEffect(() => {
        if (isImageLoading) {
            const mm = gsap.matchMedia()
            const element = ref.current

            mm.add('(max-width: 1023px)', () => {
                const tl = gsap.timeline()
                tl.set('.img-class', {
                    immediateRender: true,
                    paused: true,
                    scale: 1.2
                }).to('.img-class', {
                    scale: 1,
                    scrollTrigger: {
                        trigger: '.img-class',
                        scrub: 0.6,
                        invalidateOnRefresh: true,
                        start: 'top-=300',
                        end: 'bottom+=100',
                        // markers: true
                    }
                })
            })

            mm.add('(min-width: 1024px)', () => {
                gsap.fromTo(
                    element.querySelector('.image').querySelectorAll('img')[2],
                    {
                        scale: 1.4
                    },
                    {
                        scale: 1,
                        ease: 'power4.out',
                        scrollTrigger: {
                            invalidateOnRefresh: true,
                            trigger: element,
                            scrub: 0.6,
                            start: element.clientWidth * 1.5,
                            end: element.clientWidth * 5
                        }
                    }
                )
            })
        }
    }, [isImageLoading])

    return (
        <section
            className="screen-3 relative isolate grid text-dark_green lg:h-screen    lg:grid-cols-[0.5875fr_0.4125fr]"
            ref={ref}>
            <div className="absolute left-0 top-0 z-[-1] h-full w-full bg-beige lg:w-[200%]"></div>
            <div className="flex flex-col px-5 py-20 lg:justify-between lg:pb-[87px] lg:pt-[112px] lg:pl-32 lg:pr-[95px] xl:px-[9vw] xl:pt-[8.75vw] xl:pb-[6.5vw]">
                {title && (
                    <h3 className="book-md title mb-16 hidden lg:mb-0 lg:block">
                        {parse(title)}
                    </h3>
                )}
                {mobileTitle && (
                    <h3 className="book-md title mb-16 lg:mb-0 lg:hidden">
                        <p>{mobileTitle}</p>
                    </h3>
                )}
                <div className="lg:max-w-[400px] xl:max-w-[74.5%]">
                    {amenitiesTitle && (
                        <p className="regular-xxs pre-header mb-4 text-dark_green/50 lg:opacity-0">
                            {amenitiesTitle}
                        </p>
                    )}

                    {amenitiesDescription && (
                        <p className="content regular-xs lg:opacity-0">
                            {amenitiesDescription}
                        </p>
                    )}
                </div>
            </div>
            <div className="relative aspect-[374/406] w-full lg:my-6 lg:aspect-auto xl:my-[3vh]">
                <GatsbyImage
                    image={getImage(image?.localFile)}
                    alt={image.altText || image.title}
                    className="image h-full w-full lg:w-auto"
                    objectFit="cover"
                    objectPosition="center"
                    imgClassName="img-class"
                    onLoad={() => {
                        setIsImageLoading(true)
                    }}
                    style={{ aspectRatio: `${aspectRatio}` }}
                />
            </div>
        </section>
    )
}

export default ScreenThree
