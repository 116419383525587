import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
export default function Image({ image, className = '', loading = 'lazy' }) {
    return (
        <GatsbyImage
            image={getImage(image?.localFile)}
            alt={image?.altText || image?.title}
            className={className}
            loading={loading}
        />
    )
}
